import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Privacy from './Privacy';

import Home from './Home';

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-NMKSMHQ",
};

TagManager.initialize(tagManagerArgs);


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  )
}

export default App;
