import React from 'react';

import { Box, Flex, Center,Heading, Text,  List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,} from "@chakra-ui/react";
function Privacy() {
  return (
    <Box>
      <Heading size="3xl">Privacy Policy</Heading>
      <br></br>

      <Text><strong>Effective Date: Dec 8 2023</strong></Text>
      <Text><strong>Greenberg Labs LLC</strong> is committed to maintaining the trust and confidence of our customers and visitors to our web site. In particular, we want you to know that Greenberg Labs LLC is not currently in the business of selling, renting or trading email lists with other companies and businesses for marketing purposes. In this Privacy Policy, we’ve provided detailed information on when and why we collect your personal information, how we use it, and how we keep it secure.</Text>

      <br></br>
      <Heading as='h2' size='2xl'>Collection and Use of Personal Information</Heading>
      <br></br>

      <OrderedList>
        <ListItem color="black"><strong>Personal Information We Collect</strong>: We collect personal information about you in the course of our business, including through your use of our website and app, when you contact or request information from us, when you engage our services or as a result of your relationship with one or more of our staff and clients.</ListItem>
        <br></br>
        <br></br>
        <ListItem color="black"><strong>Use of Personal Information</strong>: The personal information that we collect and store relating to you is primarily used to enable us to provide our services to you. In addition, we may use the information for the following purposes:
          <UnorderedList >
            <ListItem color="black">To provide you with information requested from us, relating to our products or services.</ListItem>
            <ListItem color="black">To provide information on other products which we feel may be of interest to you.</ListItem>
            <ListItem color="black">To notify you about any changes to our website, such as improvements or service/product changes, that may affect our service.</ListItem>
          </UnorderedList>
        </ListItem>
      </OrderedList>

      <br></br>
      <Heading as='h2' size='lg'>Data Sharing and Disclosure</Heading>

      <OrderedList>
        <ListItem color="black"><strong>No Selling of Personal Information</strong>: Under no circumstances will we sell or rent your personal information to any third party.</ListItem>
        <ListItem color="black"><strong>Third-Party Service Providers</strong>: We may pass your information to our third-party service providers, subcontractors, and other associated organizations for the purposes of completing tasks and providing services to you on our behalf. However, when we use third-party service providers, we disclose only the personal information that is necessary to deliver the service.</ListItem>
        <ListItem color="black"><strong>Legal Requirements</strong>: We may disclose your information if required by law or if we believe that such action is necessary to protect and defend the rights, property or personal safety of the company, our customers or others.</ListItem>
        </OrderedList>


      <Heading as='h2' size='lg'>Changes to this Privacy Policy</Heading>

      <Text>We may change this Privacy Policy from time to time, and if we do we’ll post any changes on this page. If you continue to use our services after those changes are in effect, you agree to the new policy. We encourage you to check this page regularly for updates.</Text>

      <Heading as='h2' size='lg'>Contact</Heading>

      <Text>If you have any questions about this Privacy Policy, please contact us at <a href="mailto: support@dinnerspinner.io">support@dinnerspinner.io</a>.</Text>
    </Box>
  );
}

export default Privacy;